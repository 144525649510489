import _ from "lodash";
import { polyfill } from "es6-promise";
import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createHead } from "@unhead/vue";
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";

import env from "./env";
import config from "./config";
import "./yup/yupCustomRules";
import GTMService from "@/services/GTMService";
import i18n from "./i18n";
import pinia from "./stores";
import router from "./routes";
import vuetify from "./vuetify";
import App from "./App.vue";

polyfill();

window._ = _;

const app = createApp(App);

if ("production" === import.meta.env.MODE) {
	Sentry.init({
		app,
		dsn: "https://5f7ea85b8fa64fe99030cb2751d5df9a@o338056.ingest.sentry.io/6396171",
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.captureConsoleIntegration()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/ebut\.pl\/api/, /^https:\/\/beta\.ebut\.pl\/api/, /^https:\/\/dev\.ebut\.pl\/api/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.0,
		replaysOnErrorSampleRate: 1.0,
	});
}

app.config.globalProperties.$env = env;
app.config.globalProperties.$config = config;
app.config.globalProperties.$baseUrl = env.BASE_URL;
app.use(vuetify);
app.use(i18n);
app.use(pinia);
app.use(router);
if ("production" === import.meta.env.MODE) {
	app.use(
		createGtm({
			id: GTMService.gtm.id,
			vueRouter: router,
		})
	);
}
app.use(createHead());
app.mount("#app");
